import {
  PROJECT_URL,
} from '../utils/apiRouting';

import {
  get, post, put, httpDelete, formatQuery,
} from '../utils/http';

import { formatServerDate } from './date';

import { getServerHost } from './session';

export const projectStatuses = [{
  id: 'pending',
  name: 'Pending',
  order: 1,
}, {
  id: 'in_progress',
  name: 'Active',
  order: 0,
}, {
  id: 'paused',
  name: 'Paused',
  order: 2,
}, {
  id: 'archived',
  name: 'Archived',
  order: 3,
}];

export async function getProjects(query = {}) {
  return get(PROJECT_URL + formatQuery(query));
}

export async function getProject(id) {
  return get(`${PROJECT_URL}/${id}`);
}

export async function createProject(project) {
  return post(PROJECT_URL, project);
}

export async function updateProject(project) {
  return put(`${PROJECT_URL}/${project._id}`, project);
}

export async function getProjectAllocation(projectId, query) {
  return get(`${PROJECT_URL}/${projectId}/allocation${formatQuery(query)}`);
}

export async function createProjectAllocation(allocation) {
  return post(`${PROJECT_URL}/${allocation.projectId}/allocation`, allocation);
}

export async function updateProjectAllocation(allocation) {
  return put(`${PROJECT_URL}/${allocation.projectId}/allocation/${allocation._id}`, allocation);
}

export async function deleteProjectAllocation(allocation) {
  return httpDelete(`${PROJECT_URL}/${allocation.projectId}/allocation/${allocation._id}`);
}

export async function getMonthlySummary(year, month) {
  return get(`${PROJECT_URL}/summary-monthly/${year}/${month}`);
}

export async function getProjectMonthlySummary(projectId, year, month) {
  return get(`${PROJECT_URL}/${projectId}/summary-monthly/${year}/${month}`);
}

// Logs
export async function getProjectLogs(projectId, query) {
  if (query.workedAt) {
    query.workedAt = formatServerDate(query.workedAt);
  }

  return get(`${PROJECT_URL}/${projectId}/logs/${formatQuery(query)}`);
}

export async function createProjectLog(projectId, log) {
  return post(`${PROJECT_URL}/${projectId}/logs`, log);
}

export async function updateProjectLog(projectId, log) {
  return put(`${PROJECT_URL}/${projectId}/logs/${log._id}`, log);
}

export async function deleteProjectLog(projectId, log) {
  return httpDelete(`${PROJECT_URL}/${projectId}/logs/${log._id}`);
}

// Overtime
export async function getProjectOvertime(projectId, query) {
  if (query.date) {
    query.date = formatServerDate(query.date);
  }

  return get(`${PROJECT_URL}/${projectId}/overtime/${formatQuery(query)}`);
}

export async function createProjectOvertime(projectId, item) {
  return post(`${PROJECT_URL}/${projectId}/overtime`, item);
}

export async function updateProjectOvertime(projectId, item) {
  return put(`${PROJECT_URL}/${projectId}/overtime/${item._id}`, item);
}

export async function deleteProjectOvertime(projectId, item) {
  return httpDelete(`${PROJECT_URL}/${projectId}/overtime/${item._id}`);
}

export function getExportProjectLogsLink(projectId, year, month) {
  return `${getServerHost() + PROJECT_URL}/${projectId}/export-logs/${year}/${month}`;
}

export function formatLogSource(log) {
  if (log.source === 'auto') {
    return 'Auto';
  }

  return log.source ? log.source : (log.jiraLogId ? 'JIRA' : 'Manual');
}
