import {
  PAYROLL_URL,
} from '../utils/apiRouting';

import {
  get, post, put, httpDelete,
} from '../utils/http';

export const statusOptions = [
  {
    id: 'created',
    name: 'Created',
  },
  {
    id: 'pending',
    name: 'Pending',
  },
  {
    id: 'sent_for_approval',
    name: 'Sent For Approval',
    description: 'Approved by Finance and sent for approval to the Partner',
  },
  {
    id: 'approved',
    name: 'Approved',
    description: 'Approved by the Partner',
  },
  {
    id: 'sent_fop',
    name: 'Sent FOP',
    description: 'Payment was sent to FOP and need to be sent to a partner card by accountant',
  },
  {
    id: 'completed',
    name: 'Payed',
  },
];

export const bonusOptions = [
  { id: 'sales', name: 'Sales' },
  { id: 'am', name: 'Account Manager' },
  { id: 'dp', name: 'Domestic Partner' },
  { id: 'lead_generator', name: 'Lead Generator' },
  { id: 'referral', name: 'Referral' },
  { id: 'recruiter', name: 'Recruiter' },
];

export function getPaymentCurrency() {
  return 'UAH';
}

export async function createPayroll(year, month) {
  return post(`${PAYROLL_URL}/${year}/${month}`);
}

export async function getPayrollRecord(id) {
  return get(`${PAYROLL_URL}/records/${id}`);
}

export async function updatePayrollRecord(record) {
  return put(`${PAYROLL_URL}/records/${record._id}`, record);
}

export async function updatePayrollRecordStatus({ _id, status }) {
  return put(`${PAYROLL_URL}/records/${_id}/status`, { status });
}

export function getNextStatuses(currentStatus) {
  const statusFlow = {
    created: [],
    pending: ['sent_for_approval'],
    sent_for_approval: ['approved'],
    approved: ['sent_fop', 'completed'],
    sent_fop: ['completed'],
    completed: [],
  };

  return statusFlow[currentStatus];
}

export function canChangeStatus(status) {
  if (!status) {
    return false;
  }

  return Boolean(getNextStatuses(status).length);
}

export function canChangeRecord(record) {
  return ['created', 'pending', 'sent_for_approval', 'approved'].indexOf(record.status) > -1;
}

export function needChangeRecordWarning(record) {
  return ['sent_for_approval', 'approved'].indexOf(record.status) > -1;
}

export async function getPayrollPaymentMethods() {
  return get(`${PAYROLL_URL}/payment-methods`);
}

export function formatPayrollPaymentMethodOptions(paymentMethods) {
  return paymentMethods.map((method) => ({
    id: method._id,
    name: method.name,
  }));
}

export async function createPayrollPaymentMethod(method) {
  return post(`${PAYROLL_URL}/payment-methods`, method);
}

export async function updatePayrollPaymentMethod(method) {
  return put(`${PAYROLL_URL}/payment-methods/${method._id}`, method);
}

export async function deletePayrollPaymentMethod(method) {
  return httpDelete(`${PAYROLL_URL}/payment-methods/${method._id}`);
}

export async function getPayrollPaymentTargets() {
  return get(`${PAYROLL_URL}/payment-targets`);
}

export async function createPayrollPaymentTarget(target) {
  return post(`${PAYROLL_URL}/payment-targets`, target);
}

export function formatPayrollPaymentTargetOptions(paymentTargets) {
  return paymentTargets.map((target) => ({
    id: target._id,
    name: target.name,
  }));
}

export function formatBonusType(type) {
  return bonusOptions.find((option) => option.id === type).name;
}
