import {
  CLIENT_URL,
} from '../utils/apiRouting';

import {
  get, post, put, formatQuery,
} from '../utils/http';

export async function getClients(query = {}) {
  return get(CLIENT_URL + formatQuery(query));
}

export async function getClient(id) {
  return get(`${CLIENT_URL}/${id}`);
}

export async function createClient(client) {
  return post(CLIENT_URL, client);
}

export async function updateClient(client) {
  return put(`${CLIENT_URL}/${client._id}`, client);
}
