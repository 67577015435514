import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import {
  getClients,
} from 'services/client';

import {
  getClientContracts,
  getClientContractPeriod,
} from 'services/clientContract';

import {
  formatDate,
} from 'services/date';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

import PagedTable from 'components/PagedTable/PagedTable';

import { ConsoleContext } from 'services/context';

const useStyles = makeStyles((theme) => ({
}));

export default function ClientContractList() {
  const classes = useStyles();

  const { showError, showLoader } = useContext(ConsoleContext);

  const [rows, setRows] = useState([]);

  const columns = [
    { id: 'companyName', label: 'Client' },
    { id: 'startAt', label: 'Start Date' },
    { id: 'endAt', label: 'End Date' },
  ];

  useEffect(() => {
    (async () => {
      showLoader(true);

      try {
        const clients = await getClients();
        const contracts = await getClientContracts();

        const rows = clients.map((client) => {
          const { startAt, endAt } = getClientContractPeriod(client._id, contracts);

          return {
            id: client._id,
            companyName: (<Link to={`client-contracts/${client._id}`}>{client.companyName}</Link>),
            startAt: startAt ? formatDate(startAt) : '-',
            endAt: endAt ? formatDate(endAt) : '-',
          };
        });

        setRows(rows);
      } catch (error) {
        showError(error);
      }

      showLoader(false);
    })();
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <PagedTable columns={columns} rows={rows} />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
