export default [
  'Unknown Error',
  'Invoice Number',
  '{{locale.name}} Messages',
  'PP Report',
  'Invoices',
  'Partner Contracts',
  'Client Contracts',
  'Roles',
  'Client',
  'Recent Invoices',
  'Click to view Invoices',
  'Click to view Invoice',
  'Start Date',
  'End Date',
  'Total Amount',
  'Payed Amount',
  'Changed Status At',
  '{{client.companyName}} Invoices',
  'Create Invoice',
  'Created',
  'Sent',
  'Completed',
  'Rejected',
  'Billing Period Amount',
  'Previous Payment Debt',
  'Discount',
  'Additional Amount',
  'Service Compensation',
  'Status',
  'Created Date',
  'Sent Date',
  'Completed Date',
  'Rejected Date',
  'Invoice updated',
  'Invoice created',
  'Back to {{client.companyName}} invoices',
  'Edit Invoice',
  'View Invoice',
  'Should be negative or 0',
  'Comment',
  'Should be 0 or larger',
  'Update',
  'Cancel',
  'Create',
  'Summary',
  'Edit',
  'Change Status',
  'view details',
  'details',
  '#',
  'Description',
  'Amount',
  'Unpaid Amount',
  'Total',
  'Previous Payment Debt Details',
  'Close',
  'Status changed',
  'Save',
  'Start Date is required',
  'End Date is required',
  'End Date must be after Start Date',
  'Discount should be negative or 0',
  'Additional Amount should be 0 or larger',
  'Service Compensation should be 0 or larger',
  'Payed Amount is required',
  'Payed Amount should be larger than 0',
  'Billing Period',
  'Invoice Details',
  'Download',
  'Click to resolve issues',
  'Project',
  'Click to view Client Contracts',
  'Revenue Plan',
  'Revenue Fact',
  'Client Weight by GP Plan',
  'Client Weight by GP Fact',
  'Revenue for Overtime',
  'Billable Direct Costs Plan',
  'Billable Direct Costs Fact',
  'Non Billable Direct Costs Plan',
  'Non Billable Direct Costs Fact',
  'Costs for Overtime Fact',
  'GP Plan',
  'GP Fact',
  'GPM Plan',
  'GPM Fact',
  'Click to view Partner Contracts',
  'Partner',
  'Client Profitability',
  'No data',
  'Partner Profitability',
  'Year',
  'Month',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
  'Monthly Rate',
  'Hourly Rate',
  'Type',
  'Monthly',
  'Hourly',
  'Contract created',
  'Contract updated',
  'Contract deleted',
  'Currency',
  'Invalid Currency',
  'Currency Exchange Date',
  'Exchange Rates',
  'No currency exchange was performed',
  'Date',
  'Result',
  'Calculate in {{currency}}',
  'Convert to {{currency}}',
  'Apply',
  'Invoice for this billing period already exists',
  'Dates with revenue after the last invoice must be included',
  'Missing Partner Contracts',
  'Missing Client Contracts',
  'All Projects',
  'Manager',
  'All Managers',
  'All Clients',
  'Tax Compensation',
  'Edit Contract',
  'Create Contract',
  'Contract Type',
  'Yes',
  'No',
  'Monthly Rate is required',
  'Hourly Rate is required',
  'Monthly Rate should be a number',
  'Hourly Rate should be a number',
  'Confirm',
  'Ok',
  'Are you sure?',
  'Are you sure <1>{{type}}</1> is <1>{{rate}}</1>?',
  'Hours/Day',
  'Prepayment',
  'No contracts',
  'No projects',
  'Leave empty if it\'s 8 hours a day',
  'Exclude from <1>Prepayment</1>',
  'Loading allocation',
  'No allocation',
  'Use dates',
  'Allocation',
  'No members. Can\'t create a contract',
  'Project {{projectName}}',
  '{{name}} Contracts',
  'Hours in Day should be a number',
  'Contract with same conditions already exists',
  'Not found',
  'Payroll',
  'Payment Date',
  'Working Hours',
  'Billable Hours',
  'Payment Amount',
  'Payment From',
  'All Payment From',
  'All Payment To',
  'All Statuses',
  'Pending',
  'Sent For Approval',
  'Approved',
  'Payed',
  'Add',
  'Payment Method',
  'No payment methods',
  'Add Payment From',
  'Name',
  'Payment From added',
  'Payment From updated',
  'Payment From deleted',
  'Can\'t delete Payment From with assigned Payroll Records',
  'Partners',
  'Name is required',
  'Payment From already exists',
  'Back to {{userName}}',
  'Go to {{userName}}',
  'View Payroll Record',
  'Edit Payroll Record',
  'Billable By Company Dayoff Hours',
  'Non Billable By Company Dayoff Hours',
  'Overtime Hours',
  'Overtime Amount',
  'Worked Hours',
  'Billable By Company Hours',
  'Billable By Company Amount',
  'Holiday Compensation Hours',
  'Holiday Compensation Amount',
  'Bonuses',
  'Prepayments',
  'Missing Partner Contract Days',
  'Sent For Approval Date',
  'Approved Date',
  'Payed Date',
  'Recalculate',
  'Holiday Compensation Hours should be 0 or larger',
  'Tax Compensation should be a number',
  'Prepayment Amount should be 0 or larger',
  'Prepayment Comment is required',
  'Prepayment Currency is required',
  'Prepayment Date is required',
  'Prepayment Date should be today or in the past',
  'Prepayment date should be within the payment period',
  'Bonus should be 0 or larger',
  'Not permitted status change',
  'Payment From is required',
  'Payment To',
  'Payment To is required',
  'Payment To already exists',
  'Add missing Partner Contracts',
  'Can\'t change Status before Payment Date',
  'Can\'t change Payroll Record with this status',
  'Payment To should be specified in order to download CSV',
  'Add missing Partner Contracts in order to download CSV',
  'Payroll Record Details',
  'Billable Dayoff Hours',
  'Billable Amount',
  'Fix it',
  'Newer invoices already exist',
  'Billing Report',
  'P&L Revenue',
  'Approved by Finance and sent for approval to the Partner',
  'Approved by the Partner',
  'Status is required',
  'Delete',
  'Can\'t modify archived data',
  'Debt Details',
  'Hours Change',
  'Amount Change',
  'Reason',
  'Unknown',
  'Log changed',
  'Overtime changed',
  'Was not included in previous invoice prepayment',
  'Rate changed',
  'Allocation plan changed',
  'Work calendar changed',
  'Partner work calendar changed',
  'This will change payroll record status to Pending',
  'Can\'t modify until status becomes Pending',
  'Add Payment To',
  'Sent FOP',
  'Payment was sent to FOP and need to be sent to a partner card by accountant',
  'Sent FOP Date',
  'Will be added to P&L',
  'Will NOT be added to P&L',
];
