import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import {
  getClient,
  createClient,
  updateClient,
} from 'services/client';

import { getUsers } from 'services/user';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody';
import Button from 'components/CustomButtons/Button.js';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import CardFooter from 'components/Card/CardFooter.js';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import { ConsoleContext } from 'services/context';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
  },
}));

export default function ClientDetails({ match: { params: { id } } }) {
  useEffect(() => {
    async function fetchData() {
      showLoader(true);

      try {
        let client = {};

        if (id) {
          client = await getClient(id);
        }

        const users = await getUsers();

        setUsers(users);

        setClient({
          companyName: '',
          firstName: '',
          lastName: '',
          managerId: '',
          ...client,
        });
      } catch (error) {
        showError(error.message);
      }

      showLoader(false);
    }

    fetchData();
  }, [id]);

  const classes = useStyles();

  const { showError, showSuccess, showLoader } = useContext(ConsoleContext);

  const [client, setClient] = useState({
    companyName: '',
    firstName: '',
    lastName: '',
    managerId: '',
  });

  const [redirectId, setRedirectId] = useState('');

  const [users, setUsers] = useState([]);

  const handleValueChange = (field, value) => {
    const data = { ...client };
    data[field] = value;

    setClient(data);
  };

  const handleSubmit = async () => {
    const data = { ...client };

    let newClient;

    if (!data.managerId) {
      delete data.managerId;
    }

    try {
      if (client._id) {
        newClient = await updateClient(data);

        showSuccess('Client saved');

        setClient({
          managerId: '',
          ...newClient,
        });
      } else {
        newClient = await createClient(data);

        showSuccess('Client created');

        setRedirectId(newClient._id);
      }
    } catch (e) {
      showError(e);
    }
  };

  return (
    <div>
      { redirectId ? <Redirect to={`/general/clients/${redirectId}`} /> : ''}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          { client ? (
            <Card>
              <CardHeader color="success">
                <h4>{client._id ? 'Edit Client' : 'Create Client'}</h4>
                {client._id ? client.companyName : ''}
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <TextField
                      fullWidth
                      required
                      label="Company Name"
                      value={client.companyName}
                      onChange={(e) => handleValueChange('companyName', e.target.value)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <TextField
                      fullWidth
                      label="First Name"
                      value={client.firstName}
                      onChange={(e) => handleValueChange('firstName', e.target.value)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <TextField
                      fullWidth
                      label="Last Name"
                      value={client.lastName}
                      onChange={(e) => handleValueChange('lastName', e.target.value)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <FormControl className={classes.formControl}>
                      <InputLabel>Manager</InputLabel>
                      <Select
                        value={client.managerId}
                        onChange={(e) => handleValueChange('managerId', e.target.value)}
                      >

                        { users
                          .map((user, index) => (
                            <MenuItem key={`user-${user._id}`} value={user._id}>
                              {`${user.firstName} ${user.lastName}`}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" onClick={handleSubmit}>{client._id ? 'Update' : 'Create'}</Button>
              </CardFooter>
            </Card>
          ) : ''}
        </GridItem>
      </GridContainer>
    </div>
  );
}
