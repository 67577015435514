import React, { useState, useEffect } from 'react';

import {
  getUserStatusOptions,
} from 'services/user';

// @material-ui/core components

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';

import CustomSelect from 'components/CustomSelect/CustomSelect';

export default function UserFilters({
  onChange,
}) {
  const [filters, setFilters] = useState({
    status: getUserStatusOptions()[0].id,
  });

  useEffect(() => {
    const data = {};

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        data[key] = filters[key];
      }
    });

    onChange(data);
  }, [filters]);

  const handleFilterChange = (filter, value) => {
    const data = { ...filters };
    data[filter] = value;

    setFilters(data);
  };

  return (
    <GridContainer>

      <GridItem xs={12} sm={12} md={3}>
        <CustomSelect
          required
          label="Status"
          value={filters.status}
          options={getUserStatusOptions()}
          onChange={(value) => handleFilterChange('status', value)}
        />
      </GridItem>

    </GridContainer>
  );
}
