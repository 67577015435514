import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import Button from 'components/CustomButtons/Button';
import ArrowButton from 'components/ArrowButton/ArrowButton';

import {
  getPayrollRecord,
  getPayrollPaymentMethods,
  formatBonusType,
  updatePayrollRecord,
  canChangeRecord,
  canChangeStatus,
  getPayrollPaymentTargets,
  needChangeRecordWarning,
} from 'services/payroll';
import {
  formatUserName,
  getUsers,
} from 'services/user';
import {
  formatMoney,
  formatNumber,
} from 'services/finance';
import {
  formatDate,
  formatDateTime,
} from 'services/date';
import { ConsoleContext } from 'services/context';
import PayrollRecordEdit from './components/PayrollRecordEdit';
import ChangeStatusDialog from './components/ChangeStatusDialog';
import PayrollRecordStatus from './components/PayrollRecordStatus';
import PayrollRecordPaymentDetails from './components/PayrollRecordPaymentDetails';
import PayrollRecordDebtDetails from './components/PayrollRecordDebtDetails';
import PayrollExchangeRates from './components/PayrollExchangeRates';

const useStyles = makeStyles((theme) => ({
  actions: {
    marginTop: '15px',
    textAlign: 'center',
  },
}));

export default function PayrollDetails(props) {
  const { id } = props.match.params;

  const classes = useStyles();

  const {
    showError, showSuccess, showLoader, can, t, getSessionStorage, showConfirm,
  } = useContext(ConsoleContext);

  const [record, setRecord] = useState();
  const [fieldValues, setFieldValues] = useState({});
  const [canEdit, setCanEdit] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [openChangeStatusDialog, setOpenChangeStatusDialog] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentTargets, setPaymentTargets] = useState([]);
  const [paging, setPaging] = useState({ prev: null, next: null });
  const [openRecordDebtDialog, setOpenRecordDebtDialog] = useState(false);
  const [users, setUsers] = useState([]);

  const storage = getSessionStorage('finance');

  const fields = [
    { id: 'billingPeriod', label: 'Billing Period' },
    { id: 'user', label: 'Partner' },
    { id: 'paymentDate', label: 'Payment Date' },
    { id: 'paymentMethod', label: 'Payment From' },
    { id: 'paymentTarget', label: 'Payment To' },
    { id: 'currency', label: 'Currency' },
    { id: 'monthlyRate', label: 'Monthly Rate' },
    { id: 'hourlyRate', label: 'Hourly Rate' },
    { id: 'billableHolidayHours', label: 'Billable By Company Dayoff Hours' },
    { id: 'nonBillableHolidayHours', label: 'Non Billable By Company Dayoff Hours' },
    { id: 'overtimeHours', label: 'Overtime Hours' },
    { id: 'overtimeAmount', label: 'Overtime Amount' },
    { id: 'workedHours', label: 'Worked Hours' },
    { id: 'billableHours', label: 'Billable By Company Hours' },
    { id: 'billableAmount', label: 'Billable By Company Amount' },
    { id: 'holidayCompensationHours', label: 'Holiday Compensation Hours' },
    { id: 'holidayCompensationAmount', label: 'Holiday Compensation Amount' },
    { id: 'bonuses', label: 'Bonuses' },
    { id: 'prepayments', label: 'Prepayments' },
    { id: 'previousRecordDebt', label: 'Previous Payment Debt' },
    { id: 'totalAmount', label: 'Total Amount' },
    { id: 'paymentTotalAmount', label: 'Total Amount' },
    { id: 'paymentTaxCompensation', label: 'Tax Compensation' },
    { id: 'paymentFinalAmount', label: 'Payment Amount' },
    { id: 'noContractDates', label: 'Missing Partner Contract Days' },
  ];

  const statusFields = [
    { id: 'status', label: 'Status' },
    { id: 'createdAt', label: 'Created Date' },
    { id: 'sentForApprovalAt', label: 'Sent For Approval Date', changedByField: 'sentForApprovalBy' },
    { id: 'approvedAt', label: 'Approved Date', changedByField: 'approvedBy' },
    { id: 'sentFopAt', label: 'Sent FOP Date', changedByField: 'sentFopBy' },
    { id: 'completedAt', label: 'Payed Date', changedByField: 'completedBy' },
  ];

  useEffect(() => {
    (async () => {
      const users = await getUsers();

      setUsers(users);
    })();
  }, []);

  useEffect(() => {
    showLoader(true);

    (async () => {
      setCanEdit(await can('finance:payroll:edit'));

      try {
        const [
          record,
          paymentMethods,
          paymentTargets,
        ] = await Promise.all([
          getPayrollRecord(id),
          getPayrollPaymentMethods(),
          getPayrollPaymentTargets(),
        ]);

        setPaymentMethods(paymentMethods);
        setPaymentTargets(paymentTargets);
        setRecord(record);

        processPaging(record);
      } catch (e) {
        showError(e);
      }

      showLoader(false);
    })();
  }, [id]);

  useEffect(() => {
    if (record && record._id && users.length) {
      processFieldValues(record, users);
    }
  }, [record, users]);

  const processFieldValues = (record, users) => {
    const {
      _id,
      user,
      userId,
      paymentDate,
      status,
      currency,
      paymentCurrency,
      monthlyRate,
      hourlyRate,
      workedHours,
      billableHolidayHours,
      nonBillableHolidayHours,
      holidayCompensationHours,
      holidayCompensationAmount,
      billableHours,
      overtimeHours,
      overtimeAmount,
      bonuses,
      bonusTotal,
      billableAmount,
      prepayments,
      prepaymentTotal,
      previousRecordDebt,
      totalAmount,
      paymentTotalAmount,
      currencyExchangeRates,
      currencyExchangeDate,
      paymentTaxCompensation,
      paymentFinalAmount,
      createdAt,
      sentForApprovalAt,
      approvedAt,
      approvedBy,
      completedAt,
      noContractDates,
      sentForApprovalBy,
      sentFopAt,
      sentFopBy,
      completedBy,
    } = record;

    setFieldValues({
      billingPeriod: formatBillingPeriod(record),
      user: formatUserName(record.user),
      status: (<PayrollRecordStatus status={status} />),
      currency: renderCurrencyExchangeInfo(record),
      paymentDate: formatDate(paymentDate),
      monthlyRate: formatMoney(monthlyRate, currency),
      hourlyRate: formatMoney(hourlyRate, currency),
      workedHours: formatHours(workedHours),
      billableHolidayHours: formatHours(billableHolidayHours),
      nonBillableHolidayHours: formatHours(nonBillableHolidayHours),
      holidayCompensationHours: formatHours(holidayCompensationHours),
      holidayCompensationAmount: formatMoney(holidayCompensationAmount),
      billableHours: formatHours(billableHours),
      overtimeHours: formatHours(overtimeHours),
      overtimeAmount: formatMoney(overtimeAmount),
      paymentMethod: renderPaymentMethod(record),
      paymentTarget: renderPaymentTarget(record),
      bonuses: renderBonuses(record),
      billableAmount: formatMoney(billableAmount, currency),
      prepayments: renderPrepayments(record),
      previousRecordDebt: renderPreviousRecordDebt(record),
      totalAmount: formatMoney(totalAmount, currency),
      paymentTotalAmount: formatMoney(paymentTotalAmount, paymentCurrency),
      paymentTaxCompensation: formatMoney(paymentTaxCompensation, paymentCurrency),
      paymentFinalAmount: formatMoney(paymentFinalAmount, paymentCurrency),
      createdAt: formatDateTime(createdAt),
      sentForApprovalAt: formatDateTime(sentForApprovalAt),
      approvedAt: formatDateTime(approvedAt),
      completedAt: formatDateTime(completedAt),
      noContractDates: renderNoContractDates(record),
      sentForApprovalBy: renderStatusChangedBy(sentForApprovalBy, users),
      approvedBy: renderStatusChangedBy(approvedBy, users),
      sentFopAt: formatDateTime(sentFopAt),
      sentFopBy: renderStatusChangedBy(sentFopBy, users),
      completedBy: renderStatusChangedBy(completedBy, users),
    });
  };

  const processPaging = (record) => {
    const rows = storage.getValue('payrollRows', []);

    const paging = {
      prev: null,
      next: null,
    };

    if (rows.length) {
      const index = rows.findIndex((row) => row.id === id);

      if (index > 0) {
        paging.prev = rows[index - 1];
      }

      if (index < rows.length - 1) {
        paging.next = rows[index + 1];
      }
    }

    setPaging(paging);
  };

  const formatHours = (hours) => `${formatNumber(hours)} ${t('hours')}`;

  const formatBillingPeriod = (record) => {
    const date = moment.utc(record.startAt);
    return `${t(date.format('MMMM'))} ${date.format('YYYY')}`;
  };

  const renderPreviousRecordDebt = (record) => {
    const { previousRecordDebt } = record;

    const handleClick = (e) => {
      e.preventDefault();
      setOpenRecordDebtDialog(true);
    };

    return (
      <>
        { formatMoney(previousRecordDebt) }
        { previousRecordDebt ? (
          <a
            href="#"
            onClick={handleClick}
            style={{ float: 'right' }}
          >
            {t('view details')}
          </a>
        ) : null }
      </>
    );
  };

  const renderPaymentMethod = (record) => {
    if (!record.paymentMethodId) {
      return '-';
    }

    const method = paymentMethods.find((method) => method._id === record.paymentMethodId);

    if (!method) {
      return '-';
    }

    return method.name;
  };

  const renderPaymentTarget = (record) => {
    if (!record.paymentTargetId) {
      return '-';
    }

    const target = paymentTargets.find((target) => target._id === record.paymentTargetId);

    if (!target) {
      return '-';
    }

    return target.name;
  };

  const renderBonuses = (record) => {
    const { bonuses, bonusTotal, currency } = record;

    if (!bonusTotal) {
      return '-';
    }

    return (
      <TableContainer>
        <Table>
          <TableBody>
            { bonuses.map((bonus) => (
              <TableRow key={bonus._id}>
                <TableCell>{ t(formatBonusType(bonus.type)) }</TableCell>
                <TableCell>{ formatMoney(bonus.amount, currency) }</TableCell>
              </TableRow>
            )) }
            <TableRow>
              <TableCell>
                <strong>{ t('Total') }</strong>
              </TableCell>
              <TableCell>
                <strong>{ formatMoney(bonusTotal, currency) }</strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderPrepayments = (record) => {
    const {
      prepayments,
      prepaymentTotal,
      currency,
    } = record;

    if (!prepaymentTotal) {
      return '-';
    }

    return (
      <TableContainer>
        <Table>
          <TableBody>
            { prepayments.map((prepayment) => (
              <TableRow key={prepayment._id}>
                <TableCell>{ formatDate(prepayment.date) }</TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>{ formatMoney(prepayment.amount, prepayment.currency) }</TableCell>
                <TableCell style={{ maxWidth: '100px' }}>{ prepayment.comment }</TableCell>
              </TableRow>
            )) }
            <TableRow>
              <TableCell>
                <strong>{ t('Total') }</strong>
              </TableCell>
              <TableCell>
                <strong>{ formatMoney(prepaymentTotal, currency) }</strong>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderCurrencyExchangeInfo = (record) => (
    <PayrollExchangeRates
      paymentCurrency={record.paymentCurrency}
      currencyExchangeDate={record.currencyExchangeDate}
      currencyExchangeRates={record.currencyExchangeRates}
    />
  );

  const renderNoContractDates = (record) => {
    const { userId, noContractDates } = record;

    if (!noContractDates || !noContractDates.length) {
      return '-';
    }

    const dates = noContractDates.map((date) => moment.utc(date).format('D')).join(', ');

    return (
      <>
        <span style={{ color: 'red' }}>{dates}</span>

        <Link
          to={`/finance/partner-contracts/${userId}`}
          target="_blank"
          style={{ marginLeft: '10px' }}
        >
          <Button color="primary" size="sm">{t('Fix it')}</Button>
        </Link>
      </>
    );
  };

  const renderStatusChangedBy = (changedByUserId, users) => {
    if (!changedByUserId) {
      return '-';
    }

    const user = users.find((user) => user._id === changedByUserId);

    if (!user) {
      return '-';
    }

    return formatUserName(user);
  };

  const handleEdit = () => {
    showChangeRecordWarning(() => {
      setEditMode(true);
    });
  };

  const showChangeRecordWarning = (callback) => {
    if (needChangeRecordWarning(record)) {
      showConfirm(() => {
        callback();
      }, t('This will change payroll record status to Pending'));
    } else {
      callback();
    }
  };

  const handleCancelEdit = () => {
    setEditMode(false);
  };

  const handleSave = (record) => {
    setRecord(record);
    setEditMode(false);
  };

  const handleRecalculate = async () => {
    showChangeRecordWarning(async () => {
      showLoader(true);

      try {
        const changedRecord = await updatePayrollRecord(record);

        setRecord(changedRecord);
      } catch (e) {
        showError(e);
      }

      showLoader(false);
    });
  };

  const handleChangeStatus = () => {
    setOpenChangeStatusDialog(true);
  };

  const handleChangeStatusClose = () => {
    setOpenChangeStatusDialog(false);
  };

  const handleChangeStatusSave = (changedRecord) => {
    setRecord(changedRecord);
    setOpenChangeStatusDialog(false);
  };

  const handleAddPaymentTarget = (target) => {
    setPaymentTargets([...paymentTargets, target]);
  };

  return (
    <>
      { record ? (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            { paging.prev ? (
              <ArrowButton to={`/finance/payroll/${paging.prev.id}`}>
                { t('Back to {{userName}}', { userName: paging.prev.userName }) }
              </ArrowButton>
            ) : null }

            { paging.next ? (
              <div style={{ float: 'right' }}>
                <ArrowButton to={`/finance/payroll/${paging.next.id}`} next>
                  { t('Go to {{userName}}', { userName: paging.next.userName }) }
                </ArrowButton>
              </div>
            ) : null }
          </GridItem>
        </GridContainer>
      ) : null }

      { record ? (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4>
                  {formatUserName(record.user)}
                  ,
                  {' '}
                  {formatBillingPeriod(record)}
                </h4>
                {t(editMode ? 'Edit Payroll Record' : 'View Payroll Record')}
              </CardHeader>
              <CardBody>
                { editMode ? (
                  <PayrollRecordEdit
                    record={record}
                    paymentMethods={paymentMethods}
                    paymentTargets={paymentTargets}
                    onCancel={handleCancelEdit}
                    onSave={handleSave}
                    onAddPaymentTarget={handleAddPaymentTarget}
                  />
                ) : (
                  <>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <h4>{t('Summary')}</h4>
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TableContainer>
                              <Table>
                                <TableBody>
                                  { fields.map((field) => (
                                    <TableRow key={field.id}>
                                      <TableCell><strong>{ t(field.label) }</strong></TableCell>
                                      <TableCell>{ fieldValues[field.id] }</TableCell>
                                    </TableRow>
                                  )) }
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </GridItem>
                        </GridContainer>
                        { canEdit && canChangeRecord(record) ? (
                          <GridContainer className={classes.actions}>
                            <GridItem xs={12} sm={12} md={12}>
                              <Button color="primary" onClick={() => handleEdit()}>{t('Edit')}</Button>

                              <Button
                                color="warning"
                                onClick={() => handleRecalculate()}
                                style={{ marginLeft: '10px' }}
                              >
                                {t('Recalculate')}
                              </Button>
                            </GridItem>
                          </GridContainer>
                        ) : null }
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <h4>{t('Status')}</h4>
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TableContainer>
                              <Table>
                                <TableBody>
                                  { statusFields
                                    .filter((field) => fieldValues[field.id] !== '-')
                                    .map((field) => (
                                      <TableRow key={field.id}>
                                        <TableCell><strong>{ t(field.label) }</strong></TableCell>
                                        <TableCell>{ fieldValues[field.id] }</TableCell>
                                        <TableCell>
                                          { field.changedByField ? fieldValues[field.changedByField] : null }
                                        </TableCell>
                                      </TableRow>
                                    )) }
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </GridItem>
                        </GridContainer>
                        { canEdit && canChangeStatus(record.status) ? (
                          <GridContainer className={classes.actions}>
                            <GridItem xs={12} sm={12} md={12}>
                              <Button color="success" onClick={handleChangeStatus}>{t('Change Status')}</Button>
                            </GridItem>
                          </GridContainer>
                        ) : null }
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <PayrollRecordPaymentDetails
                          record={record}
                          renderPaymentTarget={renderPaymentTarget}
                        />
                      </GridItem>
                    </GridContainer>
                  </>
                )}

                <ChangeStatusDialog
                  record={record}
                  open={openChangeStatusDialog}
                  onClose={handleChangeStatusClose}
                  onSave={handleChangeStatusSave}
                />

                <PayrollRecordDebtDetails
                  record={record}
                  open={openRecordDebtDialog}
                  onClose={() => setOpenRecordDebtDialog(false)}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      ) : null }
    </>
  );
}
